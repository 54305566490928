import React from 'react';
import { connect } from "react-redux";
import { setCppYou } from "../../../../store/actions/SourceOfIncomeActions";
import InputStep from "../InputStep";

const CppAmountYou = ( props ) => {

    const onChange = value => {
        props.setCppAmountYou(value)
    };

    return (
        <InputStep
            label={'Monthly CPP'}
            onChange={onChange}
            max={3000}
            total={props.cppTotal}
        />
    )
};

function mapStateToProps(state) {
    return {
        cppTotal: state.sourceOfIncome.you.cpp.val
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCppAmountYou: (val) => dispatch(setCppYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CppAmountYou);